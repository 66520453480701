<script>
export default {
  name: "mProgress",
  props: {
    state: Number,
    score: [Number, String],
    name: String,
    evaluate: String,
    total: [Number, String],
    type: [Number, String]
  }
}
</script>

<template>
  <div>
    <div class="title" style="display: flex">
      <div style="width: 100rem">
        <span style="font-size: 20rem;font-weight: 700;color: #333333;">{{ name.length > 4 ? name.charAt(0) : '' }}</span>
        <span style="font-size: 16rem;font-weight: 700;color: #333333;">{{ name.length > 4 ? name.substring(1) : name }}</span>
      </div>

      <div v-if="type!=9" :style="{background: score>8?'#FFE6E6':score>=3?'#E5F2FF':'#DDFFE3'}" class="progress-box" style="flex: 1">
        <div class="point"></div>
        <div :style="{background: score>8?'linear-gradient(90deg, #FF4D4D 0%, #FF7D7D 100%)':score>=3?'linear-gradient(90deg, #B1C3FF 0%, #C8D5FF 100%)':'linear-gradient(221deg, #2FD931 0%, #4BD44D 100%)', width: (score/total)*100+'%'}" class="progress"></div>
      </div>

      <div v-else :style="{background:  score>7?'#FFE6E6':'#E5F2FF'}" class="progress-box" style="flex: 1">
        <div class="point"></div>
        <div :style="{background: score>7?'linear-gradient(90deg, #FF4D4D 0%, #FF7D7D 100%)':'linear-gradient(90deg, #B1C3FF 0%, #C8D5FF 100%)', width: (score/total)*100+'%'}" class="progress"></div>
      </div>
      <div>
        <span style="font-size: 20rem;font-weight: 700;color: #5A6DE5;">{{ score || 0 }} </span>
        <span style="font-size: 16rem;font-weight: 700;color: #5A6DE5;">分</span>
      </div>
    </div>
    <div class='content'>
      {{ evaluate }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title {
  align-items: center;
}

.progress-box {
  height: 16rem;
  border-radius: 60rem 60rem 60rem 60rem;
  margin-left: 1.04%;
  margin-right: .83%;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  position: relative;

  .point {
    width: 4rem;
    height: 4rem;
    background: #FFFFFF;
    border-radius: 50%;
    margin-left: 6rem;
    z-index: 99999999 !important;
  }

  .progress {
    position: absolute;
    height: 100%;
    //background: linear-gradient(180deg, #FF4D4D 0%, #FF7D7D 100%);
    border-radius: 60rem 60rem 60rem 60rem;
  }
}

.content {
  width: 100%;
  height: 36rem;
  font-size: 13rem;
  font-weight: 400;
  color: #666666;
  margin-top: 10rem;
  margin-bottom: 32rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

}
</style>
