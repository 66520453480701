<script>

import mProgress from './Progress.vue'

export default {
  name: 'ProgressBar',
  components: {mProgress},
  props: {
    typeData: Array,
  }
}
</script>

<template>
  <div id="main">

    <div class="left">
      <mProgress v-for="index in 3" :key="index" :evaluate="typeData[index-1].evaluate" :name="typeData[index-1].name" :score="typeData[index-1].score" :state="typeData[index-1].state" :total="typeData[index-1].total" :type="typeData[index-1].type"/>
    </div>

    <div style="width: 2rem !important;height: 317rem;background: #F7F7F7;margin: 0 2.08%"></div>

    <div class="in">
      <mProgress v-for="index in 3" :key="index+3" :evaluate="typeData[index+2].evaluate" :name="typeData[index+2].name" :score="typeData[index+2].score" :state="typeData[index+2].state" :total="typeData[index+2].total" :type="typeData[index+2].type"/>
    </div>

    <div style="width: 2rem !important;height: 317rem;background: #F7F7F7;margin: 0 2.08%"></div>

    <div class="right">
      <mProgress v-for="index in 3" :key="index+6" :evaluate="typeData[index+5].evaluate" :name="typeData[index+5].name" :score="typeData[index+5].score" :state="typeData[index+5].state" :total="typeData[index+5].total" :type="typeData[index+5].type"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#main {
  display: flex;

  .left {
    flex: 1;
    margin-top: 22rem;
  }

  .in {
    flex: 1;
    margin-top: 22rem;
  }

  .right {
    flex: 1;
    margin-top: 22rem;
  }


}
</style>
