<script>
import * as echarts from 'echarts';

export default {
  name: 'Dashboard',

  props: {
    evaluation: String,
    total: [Number, String]
  },
  watch: {
    total(val) {
      let chartDom = document.getElementById('main');
      let myChart = echarts.init(chartDom);
      let option = {
        series: [
          {
            type: 'gauge',
            startAngle: 180,
            endAngle: 0,
            center: ['50%', '100%'],
            radius: '150%',
            min: 0,
            max: 1,
            splitNumber: 8,
            axisLine: {
              lineStyle: {
                width: 10,
                color: [
                  [0.64, '#2FD931'],
                  [1, '#FF4D4D']
                ]
              }
            },
            pointer: {
              icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
              length: '25%',
              width: 10,
              offsetCenter: [0, '-45%'],
              itemStyle: {
                color: 'auto'
              }
            },
            axisTick: {
              length: 5,
              lineStyle: {
                color: '#E5F2FF',
                width: 5,
              }
            },
            splitLine: {length: 0},
            axisLabel: {
              color: '#464646',
              fontSize: 20,
              distance: -60,
              rotate: 'tangential',
              formatter: function (value) {
                return '';
              }
            },
            detail: {
              fontSize: 30,
              offsetCenter: [0, '-15%'],
              valueAnimation: true,
              formatter: function (value) {
                return Math.round(value * 100) + '';
              },
              color: 'inherit'
            },
            data: [{
              value: Number(val / 100) || 0,
            }]
          }
        ]
      };
      option && myChart.setOption(option);
      window.addEventListener('resize', function () {
        myChart.resize();
      });

    }
  },

}
</script>

<template>
  <div style="max-width: 100% !important">
    <div id="main"></div>
    <div :title="evaluation" class="text">
      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: 80%">{{ evaluation }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#main {
  width: 100%;
  height: 158rem;
  flex: 1;
}

.text {
  height: 100rem;
  width: 100%;
  font-size: 16rem;
  font-weight: 400;
  color: #3D3D3D;
  line-height: 100rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

</style>
