<template>
  <div class="app-container">
    <div class="filter-container">
      <div class="filter-container-left">
        <div class="filter-container-left-title">
          <div style="width: 6px;height: 42px;background: rgba(90,100,184,0.5);border-radius: 6px 0 6px 0;margin-right: 22px"></div>
          学生信息
        </div>
        <div class="filter-container-left-content">
          <div class="filter-container-left-content-photos" style="max-width: 170px">
            <img v-if="infoData.student&&infoData.student.photo" :src="infoData.student.photo" alt="" style="width: 100%;height: 100%;object-fit: cover">
            <div v-else style="width: 100%;height: 206px;background: #D8D8D8;border-radius: 9px;"></div>
          </div>
          <div class="filter-container-left-content-info">
            <div style="display: flex">
              <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;border-radius: 8px 0 0 0;">测评名称</span>
              <span style="flex: 1;height: 40px;background: #FFFFFF;border-radius: 0 8px 0 0;border: 1px solid #F7F7F7;text-align: center">{{ infoData.info.evaluation }}</span>
            </div>

            <div style="display: flex">
              <div style="display: flex;flex: 1;">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">学生姓名</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.student_name }}</span>
              </div>
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">校区</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.school }}</span>
              </div>
            </div>

            <div style="display: flex">
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">学号</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.student_no }}</span>
              </div>
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">年级</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.grade }}</span>
              </div>
            </div>

            <div style="display: flex">
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">性别</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.student_gender_text }}</span>
              </div>
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">行政班</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.class }}</span>
              </div>
            </div>

            <div style="display: flex">
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">年龄</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.student.age }}</span>
              </div>
              <div style="display: flex;flex: 1">
                <span style="width: 103px;height: 40px;background: #EEF1FA;border: 1px solid #F7F7F7;">{{ infoData.info.completion_at ? '完成时间' : '' }}</span>
                <span style="height: 40px;background: #FFFFFF;border: 1px solid #F7F7F7;flex: 1;white-space: nowrap;overflow: hidden;text-overflow: ellipsis !important;">{{ infoData.info.completion_at }}</span>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="filter-container-right">
        <div class="filter-container-left-title">
          <div style="width: 6px;height: 42px;background: rgba(90,100,184,0.5);border-radius: 6px 0 6px 0;margin-right: 22px"></div>
          总体评价
        </div>
        <div style="display: flex;flex-flow: column">
          <Dashboard v-show="infoData.info.state != 0" :evaluation="infoData.info.evaluate||''" :total="infoData.info.average"/>
          <t-result v-if="infoData.info.state == 0" text="该学生测试还未完成，还没有数据提交上来哦" type="empty"></t-result>
        </div>
      </div>
    </div>

    <div style="margin-top: 25px;width: 98.44%;height: 404px;background: #FFFFFF;border-radius: 8px 8px 8px 8px;">
      <div class="filter-container-left-title">
        <div style="width: 6px;height: 42px;background: rgba(90,100,184,0.5);border-radius: 6px 0 6px 0;margin-right: 22px"></div>
        测试结果
      </div>
      <div class='test-results'>
        <ProgressBar v-if="infoData.type.length" :typeData="infoData.type"/>
        <t-result v-else text="该学生测试还未完成，还没有数据提交上来哦" type="empty"></t-result>
      </div>
    </div>

    <div style="margin-top: 20px">
      <el-button type="primary" @click="$router.back();">返回</el-button>
    </div>
  </div>
</template>

<script>
import TResult from "@/components/Result.vue";
import Dashboard from "./component/Dashboard.vue";
import ProgressBar from "@/views/moral/task-manage/test-mht/component/ProgressBar.vue";

export default {
	_config:{"route":{"path":"examine","meta":{"title":"查看"}}},
  components: {ProgressBar, Dashboard, TResult},
  data() {
    return {
      infoData: {
        info: {},
        student: {},
        type: []
      }
    }
  },
  created() {
    this.$_axios2.get('api/evaluation/result/mht-details?id=' + this.$route.query.id).then(res => {
      this.infoData = res.data.data || {}
    })
  }
}


</script>
<style lang="scss" scoped>
.app-container {
  background: #F7F7F7 !important;
  width: 100%;

  .filter-container {
    display: flex;
    width: 100%;

    &-left {
      width: 58.54%;
      height: 300px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;

      &-title {
        width: 100%;
        height: 42px;
        font-size: 20px;
        font-weight: 500;
        color: #0B055B;
        display: flex;
        line-height: 42px;
      }

      &-content {
        height: 206px;
        padding-top: 22px;
        padding-left: 36px;
        padding-bottom: 30px;
        display: flex;

        &-photos {
          width: 18.33%;
          min-width: 159px;
          height: 206px;
          border-radius: 9px 9px 9px 9px;
          border: 1px solid #EDEDED;
          margin-right: 40px;
        }

        &-info {
          flex: 1;
          margin-right: 69px;
        }
      }
    }

    &-right {
      width: 38.34%;
      height: 300px;
      background: #FFFFFF;
      border-radius: 8px 8px 8px 8px;
      flex: 1;
      margin-left: 1.56%;
      margin-right: 1.56%;
    }
  }
}

.test-results {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 4.97% 30px 1.82%;
}

span {
  display: inline-block;
  line-height: 40px;
  padding-left: 22px;
  box-sizing: border-box;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis !important;
}

.t-result {
  padding: 0 !important;
}

@media (max-width: 1700px) {
  .app-container {
    .filter-container {
      flex-flow: column;

      &-left {
        width: 98.44%;
      }

      &-right {
        width: 98.44%;
        margin-top: 24px;
        margin-left: 0;
        padding-bottom: 24px;
      }
    }
  }
}

</style>
